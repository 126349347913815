//保留几位小数   第一个是数字  第二个是位数
export function Digits(num, digits) {
    digits = digits === undefined ? 2 : digits
    num = num.toString()
    let index = num.indexOf('.')
    let pow = 0
    if (index !== -1) {
      let toFixedDecimal = num.substring(digits + index + 1, digits + index + 2)
      num = num.substring(0, digits + index + 1)
      if(toFixedDecimal != ""){
        pow = digits
      }
    } else {
      num = num.substring(0)
    }
    // 多加一个 parseFloat 会把末尾的0去掉
    // return parseFloat((parseFloat(num) + (pow === 0 ? 0 : Math.pow(0.1,pow))).toFixed(digits))
    return (parseFloat(num) + (pow === 0 ? 0 : Math.pow(0.1,pow))).toFixed(digits)
  }